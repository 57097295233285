/* eslint-disable */
import { Colors as StyleColors } from './styles/colors';

export function setCookie(cname: string, cvalue: string, exdays: number) {
  const d = new Date();
  d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
  const expires = 'expires=' + d.toUTCString();
  document.cookie = cname + '=' + cvalue + ';' + expires + ';path=/';
}

export function getCookie(cname: string) {
  const name = cname + '=';
  const decodedCookie = decodeURIComponent(document.cookie);
  const ca = decodedCookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return '';
}

export function checkCookie(cname: string) {
  return getCookie(cname) !== '';
}

export const getRandomNumber = (min, max) =>
  Math.floor(Math.random() * (max - min + 1)) + min;

export const scrollTop = () => {
  window.scrollTo({ top: 0, behavior: 'smooth' });
};

export const headerColors = (
  router,
  setHeaderColor,
  setHeaderBackgroundColor,
  setHeaderLoginColor,
  isMobile,
) => {
  switch (router.pathname) {
    case '/':
      setHeaderColor(StyleColors.homeHeaderColor);
      setHeaderLoginColor(StyleColors.homeHeaderColor);
      setHeaderBackgroundColor(StyleColors.homeHeaderBackgroundColor);
      break;
    case '/about-us':
      setHeaderColor(StyleColors.infoHeaderColor);
      setHeaderLoginColor(StyleColors.infoHeaderColor);
      setHeaderBackgroundColor(StyleColors.infoHeaderBackgroundColor);
      break;
    case '/competition':
      setHeaderColor(StyleColors.prizeHeaderColor);
      setHeaderLoginColor(StyleColors.prizeHeaderColor);
      setHeaderBackgroundColor(StyleColors.prizeHeaderBackgroundColor);
      break;
    case '/earth':
      setHeaderColor(StyleColors.earthHeaderColor);
      setHeaderLoginColor(StyleColors.earthHeaderColor);
      setHeaderBackgroundColor(StyleColors.earthHeaderBackgroundColor);
      break;
    case '/content':
      setHeaderColor(StyleColors.earthHeaderColor);
      setHeaderLoginColor(StyleColors.earthHeaderColor);
      setHeaderBackgroundColor(StyleColors.contentHeaderBackgroundColor);
      break;
    case '/mentorship':
      setHeaderColor(StyleColors.mentorshipHeaderColor);
      setHeaderLoginColor(StyleColors.mentorshipHeaderColor);
      setHeaderBackgroundColor(StyleColors.mentorshipHeaderBackgroundColor);
      break;
    case '/mentors':
      setHeaderColor(StyleColors.mentorshipHeaderColor);
      setHeaderLoginColor(StyleColors.mentorshipHeaderColor);
      setHeaderBackgroundColor(StyleColors.mentorshipHeaderBackgroundColor);
      break;
    case '/earth-prize-mentors-faq':
      setHeaderColor('black');
      setHeaderLoginColor('black');
      setHeaderBackgroundColor('white');
      break;
    case '/volunteer-community-faq':
      setHeaderColor('black');
      setHeaderLoginColor('black');
      setHeaderBackgroundColor('white');
      break;
    case '/eco-clubs-faq':
      setHeaderColor('black');
      setHeaderLoginColor('black');
      setHeaderBackgroundColor('white');
      break;
    case '/volunteer':
      setHeaderColor('black');
      setHeaderLoginColor('black');
      setHeaderBackgroundColor('white');
      break;
    case '/volunteer-contact':
      setHeaderColor('black');
      setHeaderLoginColor('black');
      setHeaderBackgroundColor('white');
      break;
    case '/2025':
      setHeaderColor('#2E5B66');
      setHeaderLoginColor('#2E5B66');
      setHeaderBackgroundColor('#AFE0D7');
      break;
    case '/2025/it':
      setHeaderColor('#2E5B66');
      setHeaderLoginColor('#2E5B66');
      setHeaderBackgroundColor('#AFE0D7');
      break;
    case '/2025/es':
      setHeaderColor('#2E5B66');
      setHeaderLoginColor('#2E5B66');
      setHeaderBackgroundColor('#AFE0D7');
      break;
    case '/2025/pt':
      setHeaderColor('#2E5B66');
      setHeaderLoginColor('#2E5B66');
      setHeaderBackgroundColor('#AFE0D7');
      break;
    case '/2025/de':
      setHeaderColor('#2E5B66');
      setHeaderLoginColor('#2E5B66');
      setHeaderBackgroundColor('#AFE0D7');
      break;
    case '/2025/fr':
      setHeaderColor('#2E5B66');
      setHeaderLoginColor('#2E5B66');
      setHeaderBackgroundColor('#AFE0D7');
      break;
    case '/2025/dk':
      setHeaderColor('#2E5B66');
      setHeaderLoginColor('#2E5B66');
      setHeaderBackgroundColor('#AFE0D7');
      break;
    case '/submissions-guidelines':
      setHeaderColor(StyleColors.earthHeaderColor);
      setHeaderLoginColor(StyleColors.earthHeaderColor);
      setHeaderBackgroundColor(
        StyleColors.submissionsGuidelinesHeaderBackgroundColor,
      );
      break;
    case '/submissions-start':
      setHeaderColor(StyleColors.submissionsStartHeaderColor);
      setHeaderLoginColor(StyleColors.submissionsStartHeaderColor);
      setHeaderBackgroundColor(
        StyleColors.submissionsStartHeaderBackgroundColor,
      );
      break;
    case '/voting':
      setHeaderColor('#ffffff');
      setHeaderLoginColor('#ffffff');
      setHeaderBackgroundColor('black');
      break;
    case '/voting/confirm':
      setHeaderColor('#ffffff');
      setHeaderLoginColor('#ffffff');
      setHeaderBackgroundColor('black');
      break;
    case '/vote':
      setHeaderColor('#ffffff');
      setHeaderLoginColor('#ffffff');
      setHeaderBackgroundColor('black');
      break;
    case '/the-earth-prize-2022':
      setHeaderColor(StyleColors.prize2022HeaderColor);
      setHeaderLoginColor(StyleColors.prize2022HeaderColor);
      setHeaderBackgroundColor(StyleColors.prize2022HeaderBackgroundColor);
      break;
    case '/the-earth-prize-2023':
      setHeaderColor('white');
      setHeaderLoginColor('black');
      setHeaderBackgroundColor('#327F89');
      break;
    case '/the-earth-prize-2024':
      setHeaderColor('white');
      setHeaderLoginColor('black');
      setHeaderBackgroundColor('#327F89');
      break;
    case '/2022-educator':
      setHeaderColor('#ffffff');
      setHeaderLoginColor('#ffffff');
      setHeaderBackgroundColor('#724B4E');
      break;
    case '/2023-educator':
      setHeaderColor('#ffffff');
      setHeaderLoginColor('#ffffff');
      setHeaderBackgroundColor('#724B4E');
      break;
    case '/2024-educator':
      setHeaderColor('#ffffff');
      setHeaderLoginColor('#ffffff');
      setHeaderBackgroundColor('#724B4E');
      break;
    case '/2022-mentor':
      setHeaderColor('#ffffff');
      setHeaderLoginColor('#ffffff');
      setHeaderBackgroundColor('#E2A375');
      break;
    case '/2023-mentor':
      setHeaderColor('#ffffff');
      setHeaderLoginColor('#ffffff');
      setHeaderBackgroundColor('#E2A375');
      break;
    case '/2024-mentor':
      setHeaderColor('#ffffff');
      setHeaderLoginColor('#ffffff');
      setHeaderBackgroundColor('#E2A375');
      break;
    case '/become-partner':
      setHeaderColor('black');
      setHeaderLoginColor('black');
      setHeaderBackgroundColor('white');
      break;
    case '/alumni-contact':
      setHeaderColor('black');
      setHeaderLoginColor('black');
      setHeaderBackgroundColor('white');
      break;
    case '/terms-and-conditions':
      setHeaderColor(StyleColors.termsAndConditionsHeaderColor);
      setHeaderLoginColor(StyleColors.termsAndConditionsHeaderColor);
      setHeaderBackgroundColor(
        StyleColors.termsAndConditionsHeaderBackgroundColor,
      );
      break;
    case '/earth-prize-terms-and-conditions':
      setHeaderColor(StyleColors.termsAndConditionsHeaderColor);
      setHeaderLoginColor(StyleColors.termsAndConditionsHeaderColor);
      setHeaderBackgroundColor(
        StyleColors.earthPrizeTermsAndConditionsHeaderBackgroundColor,
      );
      break;
    case '/cookie-policy':
      setHeaderColor(StyleColors.cookiePolicyHeaderColor);
      setHeaderLoginColor(StyleColors.cookiePolicyHeaderColor);
      setHeaderBackgroundColor(StyleColors.cookiePolicyHeaderBackgroundColor);
      break;
    case '/privacy-policy':
      setHeaderColor(StyleColors.privacyPolicyHeaderColor);
      setHeaderLoginColor(StyleColors.privacyPolicyHeaderColor);
      setHeaderBackgroundColor(StyleColors.privacyPolicyHeaderBackgroundColor);
      break;
    case '/copyright-policy':
      setHeaderColor(StyleColors.copyrightPolicyHeaderColor);
      setHeaderLoginColor(StyleColors.copyrightPolicyHeaderColor);
      setHeaderBackgroundColor(
        StyleColors.copyrightPolicyHeaderBackgroundColor,
      );
      break;
    case '/rules':
      setHeaderColor(StyleColors.copyrightPolicyHeaderColor);
      setHeaderLoginColor(StyleColors.copyrightPolicyHeaderColor);
      setHeaderBackgroundColor(
        StyleColors.copyrightPolicyHeaderBackgroundColor,
      );
      break;
    case '/participant-submission-package':
      setHeaderColor(StyleColors.participantSubmissionPackageHeaderColor);
      setHeaderLoginColor(StyleColors.participantSubmissionPackageHeaderColor);
      setHeaderBackgroundColor(
        StyleColors.participantSubmissionPackageHeaderBackgroundColor,
      );
      break;
    case '/faq':
      setHeaderColor(StyleColors.faqHeaderColor);
      setHeaderLoginColor(StyleColors.faqHeaderColor);
      setHeaderBackgroundColor(StyleColors.faqHeaderBackgroundColor);
      break;
    case '/mentors-faq':
      setHeaderColor(StyleColors.mentorsFaqHeaderColor);
      setHeaderLoginColor(StyleColors.mentorsFaqHeaderColor);
      setHeaderBackgroundColor(StyleColors.mentorsFaqHeaderBackgroundColor);
      break;
    case '/code-of-conduct':
      setHeaderColor(StyleColors.codeOfConductHeaderColor);
      setHeaderLoginColor(StyleColors.codeOfConductHeaderColor);
      setHeaderBackgroundColor(StyleColors.codeOfConductHeaderBackgroundColor);
      break;
    case '/2022-scholars':
      setHeaderColor(StyleColors.scholarsHeaderColor);
      setHeaderLoginColor(StyleColors.scholarsHeaderColor);
      setHeaderBackgroundColor(StyleColors.scholarsHeaderBackgroundColor);
      break;
    case '/2023-scholars':
      setHeaderColor('black');
      setHeaderLoginColor('black');
      setHeaderBackgroundColor('#AFE0D7');
      break;
    case '/2024-scholars':
      setHeaderColor('black');
      setHeaderLoginColor('black');
      setHeaderBackgroundColor('#AFE0D7');
      break;
    case '/scholars':
      setHeaderColor('black');
      setHeaderLoginColor('black');
      setHeaderBackgroundColor('white');
      break;
    case '/2022-finalists':
      setHeaderColor(StyleColors.finalistsHeaderColor);
      setHeaderLoginColor(StyleColors.finalistsHeaderColor);
      setHeaderBackgroundColor(StyleColors.finalistsHeaderBackgroundColor);
      break;
    case '/2023-finalists':
      setHeaderColor('white');
      setHeaderLoginColor('white');
      setHeaderBackgroundColor('#327F89');
      break;
    case '/finalists':
      setHeaderColor('white');
      setHeaderLoginColor('white');
      setHeaderBackgroundColor('#327F89');
      break;
    case '/supervisors-code-of-conduct':
      setHeaderColor(StyleColors.codeOfConductHeaderColor);
      setHeaderLoginColor(StyleColors.codeOfConductHeaderColor);
      setHeaderBackgroundColor(StyleColors.codeOfConductHeaderBackgroundColor);
      break;
    case '/2022-ceremony-registration':
      setHeaderColor(StyleColors.ceremonyRegistrationHeaderColor);
      setHeaderLoginColor(StyleColors.ceremonyRegistrationHeaderColor);
      setHeaderBackgroundColor(
        StyleColors.ceremonyRegistrationHeaderBackgroundColor,
      );
      break;
    case '/2023-ceremony-registration':
      setHeaderColor(StyleColors.ceremonyRegistrationHeaderColor);
      setHeaderLoginColor(StyleColors.ceremonyRegistrationHeaderColor);
      setHeaderBackgroundColor(
        StyleColors.ceremonyRegistrationHeaderBackgroundColor,
      );
      break;
    case '/the-earth-prize-2022-ceremony':
      setHeaderColor(StyleColors.ceremonyHeaderColor);
      setHeaderLoginColor(StyleColors.ceremonyHeaderColor);
      setHeaderBackgroundColor(StyleColors.ceremonyHeaderBackgroundColor);
      break;
    case '/the-earth-prize-2023-ceremony':
      setHeaderColor(StyleColors.ceremonyHeaderColor);
      setHeaderLoginColor(StyleColors.ceremonyHeaderColor);
      setHeaderBackgroundColor(StyleColors.ceremonyHeaderBackgroundColor);
      break;
    case '/the-earth-prize-2024-ceremony':
      setHeaderColor(StyleColors.ceremonyHeaderColor);
      setHeaderLoginColor(StyleColors.ceremonyHeaderColor);
      setHeaderBackgroundColor(StyleColors.ceremonyHeaderBackgroundColor);
      break;
    case '/partner':
      setHeaderColor('black');
      setHeaderLoginColor('black');
      setHeaderBackgroundColor('white');
      break;
    case '/map':
      setHeaderColor('black');
      setHeaderLoginColor('black');
      setHeaderBackgroundColor('white');
      break;
    case '/alumni':
      setHeaderColor('white');
      setHeaderLoginColor('white');
      setHeaderBackgroundColor('black');
      break;
    case '/eco-clubs':
      setHeaderColor(isMobile ? 'black' : 'white');
      setHeaderLoginColor('black');
      setHeaderBackgroundColor('black');
      break;
    case '/how-it-works':
      setHeaderColor('white');
      setHeaderLoginColor('white');
      setHeaderBackgroundColor('#1D8576');
      break;
    case '/course':
      setHeaderColor(StyleColors.homeHeaderColor);
      setHeaderLoginColor(StyleColors.homeHeaderColor);
      setHeaderBackgroundColor(StyleColors.homeHeaderBackgroundColor);
      break;
    case '/become-partner':
      setHeaderColor('black');
      setHeaderLoginColor('black');
      setHeaderBackgroundColor('white');
      break;
    case '/partner/internships':
      setHeaderColor('black');
      setHeaderLoginColor('black');
      setHeaderBackgroundColor('white');
      break;
    case '/partner/sponsoring':
      setHeaderColor('black');
      setHeaderLoginColor('black');
      setHeaderBackgroundColor('white');
      break;
    case '/partner/masterclasses':
      setHeaderColor('black');
      setHeaderLoginColor('black');
      setHeaderBackgroundColor('white');
      break;
    case '/2022-educator/[interview]':
      setHeaderColor('white');
      setHeaderLoginColor('white');
      setHeaderBackgroundColor('#467D86');
      break;
    case '/2023-educator/[interview]':
      setHeaderColor('white');
      setHeaderLoginColor('white');
      setHeaderBackgroundColor('#467D86');
      break;
    case '/2024-educator/[interview]':
      setHeaderColor('white');
      setHeaderLoginColor('white');
      setHeaderBackgroundColor('#467D86');
      break;
    case '/2022-mentor/[interview]':
      setHeaderColor('white');
      setHeaderLoginColor('white');
      setHeaderBackgroundColor('#724B4E');
      break;
    case '/2023-mentor/[interview]':
      setHeaderColor('white');
      setHeaderLoginColor('white');
      setHeaderBackgroundColor('#724B4E');
      break;
    case '/2024-mentor/[interview]':
      setHeaderColor('white');
      setHeaderLoginColor('white');
      setHeaderBackgroundColor('#724B4E');
      break;
    case '/learning-content':
      setHeaderColor('white');
      setHeaderLoginColor('white');
      setHeaderBackgroundColor('black');
      break;
    default:
      setHeaderColor(StyleColors.homeHeaderColor);
      setHeaderLoginColor(StyleColors.homeHeaderColor);
      setHeaderBackgroundColor(StyleColors.homeHeaderBackgroundColor);
  }
  if (router.query.interview === 'giulia-postal') {
    setHeaderColor('black');
    setHeaderLoginColor('black');
    setHeaderBackgroundColor('#B8DDD6');
  }
  if (router.query.interview === 'kathy-dada') {
    setHeaderColor('black');
    setHeaderLoginColor('black');
    setHeaderBackgroundColor('#B8DDD6');
  }
  if (router.query.interview === 'donal-enright') {
    setHeaderColor('black');
    setHeaderLoginColor('black');
    setHeaderBackgroundColor('#B8DDD6');
  }
  if (router.query.interview === 'luc-arvisais') {
    setHeaderColor('black');
    setHeaderLoginColor('black');
    setHeaderBackgroundColor('#B8DDD6');
  }
  if (router.query.interview === 'imani-hudaa') {
    setHeaderColor('black');
    setHeaderLoginColor('black');
    setHeaderBackgroundColor('#B8DDD6');
  }
  if (router.query.interview === 'aashna-saraf') {
    setHeaderColor('black');
    setHeaderLoginColor('black');
    setHeaderBackgroundColor('#B8DDD6');
  }
  if (router.query.interview === 'todd-schumacher') {
    setHeaderColor('black');
    setHeaderLoginColor('black');
    setHeaderBackgroundColor('#B8DDD6');
  }
  if (router.query.team === 'viridis') {
    setHeaderColor(StyleColors.prize2022HeaderColor);
    setHeaderLoginColor(
      isMobile
        ? StyleColors.prize2022HeaderColor
        : StyleColors.prize2022HeaderLoginColor,
    );
    setHeaderBackgroundColor('#C17366');
  }
  if (router.query.team === 'agripod') {
    setHeaderColor(StyleColors.prize2022HeaderColor);
    setHeaderLoginColor(
      isMobile
        ? StyleColors.prize2022HeaderColor
        : StyleColors.prize2022HeaderLoginColor,
    );
    setHeaderBackgroundColor('#6EB6BB');
  }
  if (router.query.team === 'agrivision') {
    setHeaderColor('black');
    setHeaderLoginColor('black');
    setHeaderBackgroundColor('#AFE0D7');
  }
  if (router.query.team === 'delavo') {
    setHeaderColor(StyleColors.prize2022HeaderColor);
    setHeaderLoginColor(
      isMobile
        ? StyleColors.prize2022HeaderColor
        : StyleColors.prize2022HeaderLoginColor,
    );
    setHeaderBackgroundColor('#337F89');
  }
  if (router.query.team === 'hemi-change') {
    setHeaderColor(StyleColors.prize2022HeaderColor);
    setHeaderLoginColor(
      isMobile
        ? StyleColors.prize2022HeaderColor
        : StyleColors.prize2022HeaderLoginColor,
    );
    setHeaderBackgroundColor('#327F89');
  }
  if (router.query.team === 'green-team') {
    setHeaderColor(StyleColors.prize2022HeaderColor);
    setHeaderLoginColor(
      isMobile
        ? StyleColors.prize2022HeaderColor
        : StyleColors.prize2022HeaderLoginColor,
    );
    setHeaderBackgroundColor('#ED9F6E');
  }
  if (router.query.team === 'water-fairies') {
    setHeaderColor(StyleColors.prize2022HeaderColor);
    setHeaderLoginColor(
      isMobile
        ? StyleColors.prize2022HeaderColor
        : StyleColors.prize2022HeaderLoginColor,
    );
    setHeaderBackgroundColor('#CD6F63');
  }
  if (router.query.team === 'climate-brawlers') {
    setHeaderColor(StyleColors.prize2022HeaderColor);
    setHeaderLoginColor('black');
    setHeaderBackgroundColor(StyleColors.prize2022HeaderBackgroundColor);
  }
  if (router.query.team === 'biocyte') {
    setHeaderColor(StyleColors.prize2022HeaderColor);
    setHeaderLoginColor(
      isMobile
        ? StyleColors.prize2022HeaderColor
        : StyleColors.prize2022HeaderLoginColor,
    );
    setHeaderBackgroundColor('#A95C5C');
  }
  if (router.query.team === 'adorbsies') {
    setHeaderColor(StyleColors.prize2022HeaderColor);
    setHeaderLoginColor(
      isMobile
        ? StyleColors.prize2022HeaderColor
        : StyleColors.prize2022HeaderLoginColor,
    );
    setHeaderBackgroundColor(StyleColors.prize2022HeaderBackgroundColor);
  }
  if (router.query.team === 'bacto-plastics') {
    setHeaderColor(StyleColors.prize2022HeaderColor);
    setHeaderLoginColor(
      isMobile
        ? StyleColors.prize2022HeaderColor
        : StyleColors.prize2022HeaderLoginColor,
    );
    setHeaderBackgroundColor('#1E8676');
  }
  if (router.query.team === 'invasive-ai') {
    setHeaderColor('white');
    setHeaderLoginColor('#78494D');
    setHeaderBackgroundColor('#ED9F6E');
  }
  if (router.query.team === 'big-gems') {
    setHeaderColor(StyleColors.prize2022HeaderColor);
    setHeaderLoginColor(StyleColors.prize2022HeaderColor);
    setHeaderBackgroundColor('#E2A375');
  }
  if (router.query.team === 'cieco') {
    setHeaderColor(StyleColors.prize2022HeaderColor);
    setHeaderLoginColor(StyleColors.prize2022HeaderColor);
    setHeaderBackgroundColor('#6FB6BB');
  }
  if (router.query.team === 'renemask') {
    setHeaderColor(StyleColors.prize2022HeaderColor);
    setHeaderLoginColor(
      isMobile
        ? StyleColors.prize2022HeaderColor
        : StyleColors.prize2022HeaderLoginColor,
    );
    setHeaderBackgroundColor('#ED9F6E');
  }
  if (router.query.team === 'cwn') {
    setHeaderColor(StyleColors.prize2022HeaderColor);
    setHeaderLoginColor(
      isMobile
        ? StyleColors.prize2022HeaderColor
        : StyleColors.prize2022HeaderLoginColor,
    );
    setHeaderBackgroundColor('#A95C5C');
  }
  if (router.query.team === 'gators') {
    setHeaderColor(StyleColors.prize2022HeaderColor);
    setHeaderLoginColor(
      isMobile
        ? StyleColors.prize2022HeaderColor
        : StyleColors.prize2022HeaderLoginColor,
    );
    setHeaderBackgroundColor('#A95C5C');
  }
  if (router.query.team === 'team-1') {
    setHeaderColor(StyleColors.prize2022HeaderColor);
    setHeaderLoginColor(
      isMobile
        ? StyleColors.prize2022HeaderColor
        : StyleColors.prize2022HeaderLoginColor,
    );
    setHeaderBackgroundColor('#6FB6BB');
  }
  if (router.query.team === 'kibra') {
    setHeaderColor(StyleColors.prize2022HeaderColor);
    setHeaderLoginColor(
      isMobile
        ? StyleColors.prize2022HeaderColor
        : StyleColors.prize2022HeaderLoginColor,
    );
    setHeaderBackgroundColor(StyleColors.prize2022HeaderBackgroundColor);
  }
  if (router.query.team === 'aquaponics') {
    setHeaderColor(StyleColors.prize2022HeaderColor);
    setHeaderLoginColor(
      isMobile
        ? StyleColors.prize2022HeaderColor
        : StyleColors.prize2022HeaderLoginColor,
    );
    setHeaderBackgroundColor(StyleColors.prize2022HeaderBackgroundColor);
  }
  if (router.query.team === 'ceres') {
    setHeaderColor('#78494D');
    setHeaderLoginColor('#78494D');
    setHeaderBackgroundColor('#ED9F6E');
  }
  if (router.query.team === 'hive-healthy') {
    setHeaderColor('#ED9F6E');
    setHeaderLoginColor('#ED9F6E');
    setHeaderBackgroundColor('#A95C5C');
  }
  if (router.query.team === 'mycoflo') {
    setHeaderColor('#ED9F6E');
    setHeaderLoginColor('#ED9F6E');
    setHeaderBackgroundColor('#327F89');
  }
  if (router.query.team === 'pebble') {
    setHeaderColor('#ED9F6E');
    setHeaderLoginColor('#ED9F6E');
    setHeaderBackgroundColor('#2E5B66');
  }
  if (router.query.team === 'coco-mellow') {
    setHeaderColor('#ED9F6E');
    setHeaderLoginColor('#ED9F6E');
    setHeaderBackgroundColor('#CD6F63');
  }
  if (router.query.team === 'sensory-ai') {
    setHeaderColor('#ED9F6E');
    setHeaderLoginColor('#ED9F6E');
    setHeaderBackgroundColor('#1D8676');
  }
  if (router.query.team === 'flood-gate') {
    setHeaderColor('#AFE0D7');
    setHeaderLoginColor('#AFE0D7');
    setHeaderBackgroundColor('#327F89');
  }
  if (router.query.team === 'falcons') {
    setHeaderColor('#ED9F6E');
    setHeaderLoginColor('#ED9F6E');
    setHeaderBackgroundColor('#CD6F63');
  }
  if (router.query.team === 'environmental-saviors') {
    setHeaderColor('#337F89');
    setHeaderLoginColor('#337F89');
    setHeaderBackgroundColor('#AFE0D7');
  }
  if (router.query.team === 'bio-tex') {
    setHeaderColor('#AFE0D7');
    setHeaderLoginColor('#AFE0D7');
    setHeaderBackgroundColor('#327F89');
  }
};

/* eslint-enable */
